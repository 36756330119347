<!-- new about us -->
<div id="content" class="site-content">
    <div class="header-content divider-bottom-left"
        style="background-image:url('assets/images/Shooting-Star-Background-in-Pure-CSS.jpg')">
        <div class="container">
            <div class="col-12">
                <h2 animation="fadeInRight" delay="0" duration="500" offset="80"
                triggerOnce="false"> The India's leading Search Specialists</h2>
                <p style="font-size:1.2em" animation="fadeInLeft" delay="0" duration="500" offset="80"
                triggerOnce="false">Our group is comprised of advertisers, creators, and designers, and we understand the stuff to obtain genuine outcomes on the web.</p>
            </div>
        </div>
    </div>
    <section class="section">
        <div class="sections">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title" animation="fadeInUp" delay="0" duration="500" offset="100"
                        triggerOnce="false">
                            <h2>About Us</h2>
                        </div>
                    </div>
                    <div class="col-md-10 offset-md-1" animation="fadeInUp" delay="0" duration="500" offset="100"
                    triggerOnce="false">
                        <div class="content">
                            <p>We are not just a digital marketing company but a company which provide many services like social media marketing, brand awareness, digital marketing and other marketing alternatives.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section section-background__img" animation="fadeInUp" delay="0" duration="500" offset="100"
    triggerOnce="false"
        style="background-image: url('assets/images/teamphoto2-1.jpg')">
        <div class="sections">
        </div>
    </section>
    <section class="section" animation="fadeInUp" delay="0" duration="500" offset="100"
    triggerOnce="false">
        <div class="sections">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title">
                            <h2>We offer internet marketing, digital marketing and social media management.</h2>
                        </div>
                    </div>
                    <div class="col-md-10 offset-md-1">
                        <div class="content">
                            <p>Nowadays, people are getting socialize on social media more than real life therefore social media has now become a good source for brand awareness. We believe that every brand needs exposure.</p>
                            <p>We design brand experiences that connect through the most powerful platforms. Forget the small talk, we believe in building relationships.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- google partners section overflowing -->

    <!-- <section animation="fadeInDown" delay="0" duration="500" offset="50"
    triggerOnce="false" class="section google-partners section-background divider-top-left divider-bottom-right"
        style="background-image: url('https://www.seoworks.co.uk/wp-content/uploads/2018/07/About-Google-Partner-Small.png')">

        <div class="container">
            <div class="row mt-5 mb-5">
                <div class="col-md-10 offset-md-1">
                    <div class="content">
                        <p><img loading="lazy" class="aligncenter size-full wp-image-6523"
                                src="/wp-content/uploads/2018/07/seoworks-google-partners.png" alt="" width="865"
                                height="158"
                                srcset="https://www.seoworks.co.uk/wp-content/uploads/2018/07/seoworks-google-partners.png 865w, https://www.seoworks.co.uk/wp-content/uploads/2018/07/seoworks-google-partners-300x55.png 300w, https://www.seoworks.co.uk/wp-content/uploads/2018/07/seoworks-google-partners-768x140.png 768w, https://www.seoworks.co.uk/wp-content/uploads/2018/07/seoworks-google-partners-200x37.png 200w, https://www.seoworks.co.uk/wp-content/uploads/2018/07/seoworks-google-partners-350x64.png 350w"
                                sizes="(max-width: 865px) 100vw, 865px"><br>
                            <span style="color: #303030;">The SEO Works is a <a>certified
                                    Google Partner</a> for Google Adwords (PPC), search, display, shopping, mobile and
                                video. We provide comprehensive professional search advertising and SEO services to
                                clients across the UK and abroad.</span>
                        </p>
                        <p><span style="color: #303030;">We pride ourselves on delivering top-quality results that are
                                based on proven techniques and an intimate, dynamic knowledge of ethical search
                                marketing practices. We’re a white-hat agency with the credentials to back it up.</span>
                        </p>
                       
                    </div>
                </div>
            </div>
        </div>

    </section> -->


    <section class="section our-values">
        <div class="sections">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="title" animation="fadeInLeft" delay="0" duration="500" offset="80"
                        triggerOnce="false">
                            <h2> Our Values</h2>
                        </div>
                    </div>
                    <div class="col-md-10 offset-md-1">
                        <div class="content" animation="fadeInRight" delay="0" duration="500" offset="80"
                        triggerOnce="false">
                            <p>Our qualities reflect what you ought to anticipate from us while cooperating</p>
                        </div>
                    </div>
                    <br>
                    <br>
                    <br>
                    <div class="values" animation="fadeInUp" delay="0" duration="500" offset="70"
                    triggerOnce="false">
                        <div class="row offset-xl-2">
                            <div class="col-sm-6 col-md-4 col-xl-2">
                                <div class="value">
                                    <div class="value-icon">
                                        <img
                                            src="assets/images/icons/icon-Driven.svg">
                                    </div>
                                    <h4>Drive</h4>
                                    <p>Headed to accomplish results, and persistently further develop guidelines</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl-2">
                                <div class="value">
                                    <div class="value-icon">
                                        <img
                                            src="assets/images/icons/icon-Adaptable.svg">
                                    </div>
                                    <h4>Adaptability</h4>
                                    <p>Versatile, with a liberal methodology that can embrace change</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl-2">
                                <div class="value">
                                    <div class="value-icon">
                                        <img
                                            src="assets/images/icons/icon-passionate.svg">
                                    </div>
                                    <h4>Passion</h4>
                                    <p>Enthusiastic about the pursuit promoting industry and the outcomes we convey for clients</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl-2">
                                <div class="value">
                                    <div class="value-icon">
                                        <img
                                            src="assets/images/icons/icon-integrity.svg">
                                    </div>
                                    <h4>Integrity</h4>
                                    <p>Deliberate, dependable and conveying what we guarantee</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-xl-2">
                                <div class="value">
                                    <div class="value-icon">
                                        <img src="assets/images/icons/icon-loyal.svg">
                                    </div>
                                    <h4>Loyalty</h4>
                                    <p>Faithful to clients, our group, and the business</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section section-background__color">

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title" animation="fadeInLeft" delay="0" duration="500" offset="80"
                    triggerOnce="false">
                        <h2> Services from an Experienced company</h2>
                    </div>
                </div>
                <div class="col-md-10 offset-md-1">
                    <div class="content" animation="fadeInRight" delay="0" duration="500" offset="80"
                    triggerOnce="false">
                        <p>We’ve been around for over 10 years, which is a long time in the world of PPC and SEO
                            services.</p>
                        <p>Our diverse client portfolio includes companies operating in <a
                                style="color: #fff; text-decoration: underline;"
                                >Financial Services</a>, <a
                                style="color: #fff; text-decoration: underline;"
                                >eCommerce</a>, <a
                                style="color: #fff; text-decoration: underline;" >Healthcare</a>,
                            <a style="color: #fff; text-decoration: underline;" >Law</a>, <a
                                style="color: #fff; text-decoration: underline;"
                                >Automotive</a>, <a
                                style="color: #fff; text-decoration: underline;"
                                >Education</a> and <a
                                style="color: #fff; text-decoration: underline;"
                                >Manufacturing</a>.</p>
                        <p>We are proud to have achieved over 10,000 top 3 rankings on Google. To see just a handful of
                            examples of our expert work, <a style="color: #fff; text-decoration: underline;"
                                >view our case studies</a>.</p>

                      
                    </div>
                </div>
            </div>
        </div>

    </section>
    <section class="section" animation="fadeInUp" delay="0" duration="500" offset="70"
    triggerOnce="false">
        <div class="sections">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title">
                            <h2>How does SEO Work?</h2>
                        </div>
                    </div>
                    <div class="col-md-10 offset-md-1">
                        <div class="content">
                            <p>Quality search engine optimisation takes time. We utilize unquestionably the most secure, best SEO administrations to both improve and give long haul rankings results. All through the <a
                                (click)="onNavigate()" routerLink="/seo">SEO cycle</a>, we furnish our clients with point by point month to month reports about our work and the continuous outcomes..</p>
                            <p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section more-services">
        <div class="sections">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title">
                            <h3>Find out More</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="service">
                            <div class="service_icon">
                                <img src="assets/images/icons/icon-loyal.svg">
                            </div>
                            <div class="service_content">
                                <h4>
                                    <a
                                        routerLink="/seo" (click)="onNavigate()">Organic
                                        Search (SEO) Services</a>
                                </h4>
                                <p>The most cost effective way to win online is through an effective organic search
                                    engine optimisation strategy....</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="service">
                            <div class="service_icon">
                                <img src="assets/images/icons/icon-Adaptable.svg">
                            </div>
                            <div class="service_content">
                                <h4>
                                    <a href="">Paid
                                        Search (PPC) Services</a>
                                </h4>
                                <p>The SEO Works are an official Google Partner, and are also Bing Accredited - helping
                                    leading companies manage the minefield that is pay per click (PPC)</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="service">
                            <div class="service_icon">
                                <img src="assets/images/icons/icon-Adaptable.svg">
                            </div>
                            <div class="service_content">
                                <h4>
                                    <a href="">Content
                                        Marketing and Content Strategy</a>
                                </h4>
                                <p>Content marketing is the online art of communicating with your customers and
                                    prospects without selling...</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="service">
                            <div class="service_icon">
                                <img src="assets/images/icons/icon-integrity.svg">
                            </div>
                            <div class="service_content">
                                <h4>
                                    <a href="">How our Google Partner
                                        status helps you</a>
                                </h4>
                                <p>The SEO Works are the only SEO and Pay Per Click specialists in the Sheffield region
                                    with certified Google Partner Specialist status...</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="service">
                            <div class="service_icon">
                                <img src="assets/images/icons/icon-Driven.svg">
                            </div>
                            <div class="service_content">
                                <h4>
                                    <a routerLink="/web-design" (click)="onNavigate()">Website and App
                                        development Services</a>
                                </h4>
                                <p>Whether you need a completely new website, in need of a freshen up on your existing
                                    website design, or require a white label websites solution, our highly skilled team
                                    have the right solution for you...</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="service">
                            <div class="service_icon">
                                <img src="assets/images/icons/icon-passionate.svg">
                            </div>
                            <div class="service_content">
                                <h4>
                                    <a routerLink="/seo" (click)="onNavigate()">International SEO Services</a>
                                </h4>
                                <p>If you are looking for international SEO services, we have a dedicated team of SEO
                                    experts who have delivered successful international campaigns, Our teams have
                                    delivered international campaigns targeting Saudi Arabia, USA, Australia and more.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>