<!-- <app-header *ngIf="isHeaderFooter"></app-header> -->
<div id="contact" class="contact-us section" [ngStyle]="{'margin-top': isHeaderFooter? '6rem':'auto'}">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 align-self-center wow" animation="fadeInLeft" delay="100" duration="800" offset="1500" triggerOnce="true">
          <div class="section-heading">
            <h2>Feel Free To Send Us a Message About Your Website Needs</h2>
            <!-- <p>Get In Touch</p> -->
            <div class="phone-info">
              <img src="assets/images/contact-us-banner.png" alt="contact" >
              <!-- <h4>For any enquiry, Call Us: <span><i class="fa fa-phone" style="color: #FFA500;"></i> <a href="tel:8728001984">8728001984</a></span></h4>
              <p><i class="fa fa-home"></i> Cabin no -2, Basement, Plot No F-471, Phase -8B, Industrial Area, Mohali, Punjab</p>
              <p><i class="fa fa-inbox"> </i> <strong>  <a style="color:white" href="mailto:hello@dopedigital.in">hello@dopedigital.in</a></strong></p> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6 wow" animation="fadeInRight" delay="100" duration="800" offset="1500" triggerOnce="true">
          <form id="contact" action="https://formsubmit.co/hello@dopedigital.in" method="POST">
            <div class="row">
              <div class="col-lg-12">
                <fieldset>
                  <input type="name" name="name" id="name" placeholder="Name" autocomplete="on" required>
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                <input type="hidden" name="_next" value="https://dopedigital.in/thanks.html" class="form-control">
              </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email" required="">
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <input type="number" placeholder="Your Number" name="number" id="number" autocomplete="on" required>
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <textarea name="message" type="text" class="form-control" id="message" placeholder="Message" required=""></textarea>  
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <button type="submit" id="form-submit" class="main-button ">Send Message</button>
                </fieldset>
              </div>
            </div>
            <div class="contact-dec">
              <img src="assets/images/contact-decoration.png" alt="">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- <app-footer *ngIf="isHeaderFooter"></app-footer> -->