import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-about',
  templateUrl: './new-about.component.html',
  styleUrls: ['./new-about.component.scss']
})
export class NewAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onNavigate() {
    window.scrollTo({
      behavior:'smooth',
      top:0
    });
  }
}
