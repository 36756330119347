<footer>
    <section id="footer-menus" class="p-100" style="padding-top:2rem">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <h4>Get In Touch</h4>
                    <ul class="menu" style="margin-left:-40px">
                        <li><a href="tel:8728001984"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z">
                                    </path>
                                </svg>+91 87280-01984</a></li>
                        <li><a href="mailto:hello@dopedigital.in"><svg xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path
                                        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                    </path>
                                </svg> hello@dopedigital.in</a></li>
                        <li><a rel="noopener"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path
                                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z">
                                    </path>
                                </svg> Cabin no -2, Basement, Plot No F-471,<br> Phase -8B, Industrial Area, Mohali,
                                Punjab</a></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                    d="M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z">
                                </path>
                            </svg> Mon-Fri, 10am-5pm</li>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <h4>Quick Links</h4>
                    <ul class="menu row">
                        <li><a routerLink="/services" (click)="onNavigate('/services')">Our Services</a></li>
                        <li><a routerLink="/web-design" (click)="onNavigate('/web-design')">Website Design</a></li>
                        <li><a routerLink="/about" (click)="onNavigate('/about')">About Us</a></li>
                        <li><a routerLink="/seo" (click)="onNavigate('/seo')">SEO Services</a></li>
                        <li><a routerLink="/blogs" (click)="onNavigate('/blogs')">Blogs</a></li>
                        <li><a routerLink="/social-media-management"
                                (click)="onNavigate('/social-media-management')">SMM</a></li>
                        <li><a routerLink="/logo-design" (click)="onNavigate('/logo-design')">Graphic Design</a></li>
                        <li><a routerLink="/clients" (click)="onNavigate('/clients')">Our Clients</a></li>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <h4>From The Blog</h4>
                    <app-loading *ngIf="isLoading"></app-loading>
                    <h6 *ngIf="!isLoading && isError">Error while loading blogs</h6>
                    <ul class="recent-blog" *ngFor="let blog of latestBlogs">
                        <li><a (click)="onNavigateToBlog(blog)">
                                <img loading="lazy" [src]="blog.imgSrc" [alt]="blog.urlTitle" style="max-width:35%">
                                <div class="content">
                                    <p [innerHTML]="blog.urlTitle"></p>
                                    <span [innerHTML]="blog.dateOfCreatingBlog"></span>
                                </div>
                            </a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section id="copyright"
        style="background-image: url('assets/images/footer-background.gif')!important;">
        <div class="row align-items-center justify-content-between">
            <div class="col-12 col-lg-auto text-center" style="margin-bottom:0">
                <div class="content mt-6 mt-sm-0">
                    <p>© 2022 Design And Developed By <a routerLink="/" (click)="onNavigate('/')">Dope Digital</a> | All
                        rights reserved | </p>
                </div>
            </div>
            <div class="col-12 col-lg-auto text-center" style="margin-right:7rem">
                <ul class="social">
                    <li>
                        <a href="https://www.facebook.com/DopeDigitalindia/" target="_blank" rel="noopener"
                            aria-label="Facebook Page">
                            <svg class="social-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path
                                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://in.linkedin.com/company/dopedigitalindia" target="_blank" rel="noopener"
                            aria-label="LinkedIn Page">
                            <svg class="social-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/dopedigital/?hl=en" target="_blank" rel="noopener"
                            aria-label="Instagram Page">
                            <svg class="social-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                </path>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</footer>