<!-- <app-header></app-header> -->
<owl-carousel-o [options]="customOptionshome" class="owl-dot">
  <ng-container>
    <ng-template carouselSlide>
      <div class="main-banner wow fadeIn" animation="fadeIn" delay="100" duration="800" offset="510" triggerOnce="true"
        id="top" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-6 align-self-center">
                  <div class="left-content header-text wow fadeInLeft" animation="fadeInLeft" delay="100" duration="800"
                    offset="510" triggerOnce="true" data-wow-duration="1s" data-wow-delay="1s">
                    <h6>Welcome to Dope Digital</h6>
                    <h1>We Make <em>Digital Ideas</em> &amp; <span>SEO</span> Marketing</h1>
                    <p>Dope Digital is one of the best and quickest growing digital marketing agency located in
                      Chandigarh.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="right-image wow  fadeInRight" animation="fadeInRight" delay="100" duration="800"
                    offset="510" triggerOnce="true">
                    <!-- banner-right-image.png -->
                    <img src="assets/images/group-business-people.jpg" alt="dopedigital hero banner">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="main-banner wow fadeIn" animation="fadeIn" delay="100" duration="800" offset="510" triggerOnce="true"
        id="top" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-6 align-self-center">
                  <div class="left-content header-text wow fadeInLeft" animation="fadeInLeft" delay="100" duration="800"
                    offset="510" triggerOnce="true" data-wow-duration="1s" data-wow-delay="1s">
                    <h6>Welcome to Dope Digital</h6>
                    <h1>We Make <em>Digital Ideas</em> &amp; <span>SEO</span> Marketing</h1>
                    <p>Dope Digital is one of the best and quickest growing digital marketing agency located in
                      Chandigarh.</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="right-image wow  fadeInRight" animation="fadeInRight" delay="100" duration="800"
                    offset="510" triggerOnce="true">
                    <!-- banner-right-image.png -->
                    <img src="assets/images/banner-right-image.png" alt="dopedigital hero banner">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>

<app-new-about></app-new-about>

<app-single-service [serviceArray]="serviceArray"></app-single-service>

<app-blogs-headline [marginTop]="marginTop" [showMoreBlogs]="showMoreBlogs"></app-blogs-headline>

<app-contact></app-contact>

<!-- <app-footer></app-footer> -->